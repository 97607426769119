<template>
  <div class="main">
    <template>
      <Tabs v-model="billSource" @tab-click="changeBillSource">
        <TabPane :label="$t('AllStatements')" name=""></TabPane>
        <TabPane :label="$t('StatementApplication')" name="system"></TabPane>
        <TabPane :label="$t('PlatformModification')" name="platform"></TabPane>
      </Tabs>
      <div class="table">
        <table>
          <thead>
          <tr>
            <th>{{$t('Billing month')}}</th>
            <th>{{$t('Billing number')}}</th><!--對帳單號-->
            <th>{{$t('StatementRange')}}</th><!--對賬範圍-->
            <th>{{$t('CreateTime')}}</th><!--建立時間-->
            <th>{{$t('StatementSource')}}</th><!--對賬單來源-->
            <th>{{$t('Bill status')}}</th>
            <th width="60">{{$t('Bill details')}}</th>
          </tr>
          </thead>
          <tbody class="tbody">
          <tr v-for="item of list" :key="item.id">
            <td>{{item.billMonth}}</td>
            <td>{{item.billNo}}</td>
            <td>{{getRange(item.billMonth,item.billRange)}}</td>
            <td>{{item.addTime|formatDate}}</td>
            <td>{{$t("billSource")[item.billSource]}}</td>
            <td class="nowrap">
              <!--<i class="status-circle"></i>
              <span class="vam">已確定</span>-->
              {{$t("BillStatus")[item.billStatus]}}
            </td>
            <td>
              <router-link class="btn-item" target="_blank" :to="{name: 'AdminSettlementDetail', params: {id:item.id}}">
                <img src="../../../assets/images/icon/review.png"/>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
        <empty v-if="isEmpty" :title="$t('NoData')"/>
        <el-pagination class="pages txt-r"
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="changePage"
                       hide-on-single-page>
        </el-pagination>
      </div>
    </template>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import {getBillList} from '../../../api/admin-settlement';
  import dateUtil from '../../../mixins/dateUtil';

  export default {
    name: 'AdminSettlementList',
    components: {Empty},
    mixins: [dateUtil],
    data() {
      return {
        billSource: '',
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    created() {
      this.getList();
    },
    methods: {
      getList(page = 1) {
        const data = {
          page,
          perPage: 20,
          billStatus: '',
          settlementStatus: '',
          billSource: this.billSource, // 对账来源<system：系统；平台修改：platform>
          billRange: '', // 对账范围<1日-14日：1；15日-月末:2；>
          billMonth: '',
          billNo: ''
        };
        this.list = [];
        this.isEmpty = false;
        getBillList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      changeBillSource(value) {
        this.getList();
      },
      getRange(yearAndMonth, type) {
        if (type === '1') {
          return '1日-14日';
        } else if (type === '2') {
          const arr = yearAndMonth.split('-');
          let year = arr[0];
          let month = arr[1];
          let days = dateUtil.methods.getDays(year, month);
          return '15日-' + days + '日';
        }
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    /deep/ .tab-nav{margin-top:0;}
    .status-circle{
      display:inline-block;width:6px;height:6px;margin-right:10px;vertical-align:middle;background-color:#00b680;border-radius:50%;
      &.orange{background-color:#ff9b00;}
      &.gray{background-color:#999;}
    }
  }
</style>
